import logo from '../HeaderLogo.png';
import { useAppStateContext } from '../contexts/AppStateContext';

export const LoginModal = () => {
  const context = useAppStateContext();

  const onCancel = () => {
    context.setIsDealerLoginModalOpen(false);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isDealer = await context.tryUnlockDealerAsync(
      (event.currentTarget.elements as any)['username'].value,
      (event.currentTarget.elements as any)['password'].value
    );
    if (isDealer) {
      alert('Dealer Features Unlocked');
      context.setIsDealerLoginModalOpen(false);
    }
  };

  const onLogout = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await context.logoutDealerAsync();
    context.setIsDealerLoginModalOpen(false);
  };

  return (
    <>
      <div className='bg-slate-50 absolute inset-0 flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img className='mx-auto h-10 w-auto' src={logo} alt='Staheli West' />
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
          <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12'>
            {!context.isDealer ? (
              <form className='space-y-6' action='#' method='POST' onSubmit={onSubmit}>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
                    Email address
                  </label>
                  <div className='mt-2'>
                    <input
                      id='username'
                      name='username'
                      type='text'
                      autoComplete='username'
                      required
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                    Password
                  </label>
                  <div className='mt-2'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div>
                  <button
                    type='reset'
                    onClick={onCancel}
                    className='flex w-full justify-center rounded-md  px-3 py-1.5 mb-2 text-sm font-semibold leading-6 shadow-sm hover:bg-primaryTransparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primaryTransparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
                  >
                    Sign in
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={onLogout}>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primaryTransparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
                >
                  Log Out
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

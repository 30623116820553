import { localStorageService } from './../../services/LocalStorageService';
import { useObservable } from '@legendapp/state/react';
import { IManualsContextValue } from './IManualsContextValue';
import { useAppStateContext } from '../AppStateContext';
import { IManualsGroup, IListItem } from '../../models/interfaces';
import { useEffect } from 'react';

const favoritesGroupTitle = 'Favorites';
const favoritesGroupId = 'manual_favorites';
const localStorageFavoriteManualsKey: string = 'FavoriteManualIds';
export const ManualsContextValue = (): IManualsContextValue => {
  const appState = useAppStateContext();
  const manuals = useObservable([] as IManualsGroup[]);

  useEffect(() => {
    loadAsync();
  }, [appState.isDealer]);

  const loadAsync = async () => {
    const manualsResponse = await fetch('/api/Manuals.json');
    const manualsData = await manualsResponse.json();
    if (appState.isDealer) {
      const dealerDocumentsResponse = await fetch('/api/DealerDocuments.json');
      const dealerDocumentsData = await dealerDocumentsResponse.json();
      _setManuals([...manualsData.Manuals, dealerDocumentsData as IManualsGroup]);
    } else {
      _setManuals(manualsData.Manuals);
    }
    await loadFavoritesAsync();
  };

  const loadFavoritesAsync = async () => {
    let newManualList = [...manuals.peek()];
    const favorites = await localStorageService.getLocalStorageItemAsync<number[]>(localStorageFavoriteManualsKey);
    if (!favorites || favorites.length === 0) {
      return;
    }

    const manualsWithoutGroups = getFlattenedListNoDuplicates(newManualList);
    manualsWithoutGroups.forEach((x) => {
      x.IsFavorite = favorites.some((f) => f === x.id);
    });

    if (newManualList.findIndex((x) => x.groupTitle === favoritesGroupTitle) === -1) {
      const favoriteManuals = manualsWithoutGroups.filter((x) => x.IsFavorite);
      const favoritesGroup = _createFavoritesGroup(favoriteManuals);
      if (favoritesGroup.pdfs.length > 0) {
        newManualList = [...newManualList, favoritesGroup];
      }
    }

    //Need to update the observable
    _setManuals(newManualList);
  };

  const favoriteManualAsync = async (manual: IListItem) => {
    const isFavorite = !manual.IsFavorite;

    manuals.forEach((group) => {
      group.pdfs.forEach((x) => {
        if (x.Url.peek() === manual.Url) {
          x.IsFavorite.set(isFavorite);
        }
      });
    });

    if (isFavorite) {
      _addToFavoritesGroup(manual);
    } else {
      _removeFromFavoritesGroup(manual);
    }

    await _saveFavoritesAsync();
  };

  const _addToFavoritesGroup = (manual: IListItem) => {
    const favoritesIndex = manuals.findIndex((x) => x.groupTitle === favoritesGroupTitle);
    const favoritesGroup = manuals[favoritesIndex];
    if (favoritesIndex === -1) {
      _setManuals([...manuals.peek(), _createFavoritesGroup([manual])]);
    } else {
      favoritesGroup.pdfs.set([...favoritesGroup.pdfs.peek(), manual]);
    }
  };

  const _removeFromFavoritesGroup = (manual: IListItem) => {
    const favoritesIndex = manuals.findIndex((x) => x.groupTitle === favoritesGroupTitle);

    if (favoritesIndex === -1) {
      //This should never happen.
      return;
    }

    const favoritesGroup = manuals[favoritesIndex];
    const filterFavorites = favoritesGroup.pdfs.peek().filter((x) => x.Url !== manual.Url);
    favoritesGroup.pdfs.set(filterFavorites);

    if (filterFavorites.length === 0) {
      _setManuals(manuals.peek().filter((x) => x.groupTitle !== favoritesGroupTitle));
      return;
    }
  };

  const _saveFavoritesAsync = async () => {
    const favoriteIds =
      manuals
        .peek()
        .find((x) => x.groupTitle === favoritesGroupTitle)
        ?.pdfs.map((x) => x.id) ?? [];
    await localStorageService.saveLocalJsonObjectAsync(localStorageFavoriteManualsKey, favoriteIds);
  };

  const _createFavoritesGroup = (manuals: IListItem[]): IManualsGroup => {
    return { id: favoritesGroupId, key: 4, groupTitle: favoritesGroupTitle, pdfs: manuals };
  };

  const _setManuals = (newList: IManualsGroup[]) => {
    const sorted = newList.sort((a, b) => (a.key > b.key ? 1 : -1));
    manuals.set(sorted);
  };

  return {
    manuals,
    favoriteManualAsync,
  };
};

const getFlattenedListNoDuplicates = (groupedManuals: IManualsGroup[]): IListItem[] => {
  return [...new Set(groupedManuals.reduce<IListItem[]>((pdfs, item) => [...pdfs, ...item.pdfs], []))];
};
